
import { Component, Vue } from "vue-property-decorator";
import HeaderWebComponent from "@/components/Common/Web/HeaderWeb.vue"; // @ is an alias to /src
import FooterWebComponent from "@/components/Common/Web/FooterWeb.vue"; // @ is an alias to /src

@Component({
  components: {
    HeaderWebComponent,
    FooterWebComponent
  }
})
export default class BaseLayout extends Vue {}
