
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";
import { Debounce } from "vue-debounce-decorator";

interface dataBusqueda {
  dataActividades: Array<any> | null;
  dataNoticias: Array<any> | null;
  dataUnidades: Array<any> | null;
  dataLinks: Array<any> | null;
}

@Component
export default class Buscador extends Mixins(FormValidator) {
  private win = window;
  private busquedaData: any = null;
  private links: any = null;

  @Watch("$store.state.modalBusqueda")
  private watchModalBusqueda(newValue: any) {
    if (!newValue) {
      this.cerrar();
    }
  }

  @Watch("$store.state.textBusqueda")
  private watchInput(newValue: string) {
    if (newValue && newValue !== "") {
      this.buscar(newValue);
    } else {
      this.busquedaData = null;
    }
  }

  get fontExterno() {
    if (this.$q.screen.xs) {
      return "15";
    }
    return "20";
  }
  get fontTitulo() {
    if (this.$q.screen.xs) {
      return "35";
    }
    return "40";
  }
  get fontTexto() {
    if (this.$q.screen.xs) {
      return "11";
    }
    return "16";
  }
  private mounted() {
    this.busquedaData = null;
    this.links = null;
  }

  @Debounce(500)
  private buscar(value: any) {
    if (value && value != "" && value.trim().length >= 3) {
      this.$axios
        .get("buscador?text=" + value)
        .then((res: any) => {
          this.busquedaData = res.data;
          for (let i = 0; i < this.busquedaData.dataGalerias.length; i++) {
            if (
              this.busquedaData.dataGalerias[i].imagen_previa &&
              this.busquedaData.dataGalerias[i].imagen_previa != ""
            ) {
              this.busquedaData.dataGalerias[i].imagen_previa =
                process.env.VUE_APP_MEDIA_URL +
                this.busquedaData.dataGalerias[i].imagen_previa;
            } else {
              this.busquedaData.dataGalerias[i].imagen_previa =
                process.env.VUE_APP_MEDIA_URL + "/Noticias/preview_default.jpg";
            }
          }
          this.links = [];
          for (let i = 0; i < this.busquedaData.dataLinksExternos.length; i++) {
            const link = this.busquedaData.dataLinksExternos[i];
            let existe = false;
            for (let j = 0; j < this.links.length; j++) {
              if (this.links[j].nombre == link.nombre) {
                existe = true;
              }
            }
            if (!existe) {
              this.links.push(link);
            }
          }
          for (let i = 0; i < this.busquedaData.dataLinks.length; i++) {
            const link = this.busquedaData.dataLinks[i];
            let existe = false;
            for (let j = 0; j < this.links.length; j++) {
              if (this.links[j].nombre == link.nombre) {
                existe = true;
              }
            }
            if (!existe) {
              this.links.push(link);
            }
          }
          for (let i = 0; i < this.busquedaData.dataPaginas.length; i++) {
            const pagina = this.busquedaData.dataPaginas[i];
            let existe = false;
            for (let j = 0; j < this.links.length; j++) {
              if (this.links[j].nombre_web == pagina.nombre_web) {
                existe = true;
              }
            }
            if (!existe) {
              this.links.push({
                id: pagina.id,
                nombre: pagina.titulo,
                url: "",
                tipo: 1,
                nombre_web: pagina.nombre_web
              });
            }
          }
          this.links.sort(this.compare);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  private cerrar() {
    this.$store.commit("SET_MODAL_BUSCADOR", false);
    this.busquedaData = {};
  }

  // private irActividad(idActividad: any, nombreWeb: any) {
  //   if (
  //     this.$router.currentRoute.name == "perfilActividades" &&
  //     this.$router.currentRoute.params.nombre == nombreWeb
  //   ) {
  //     this.$store.commit("SET_MODAL_BUSCADOR", false);
  //     this.$router.go(0);
  //   } else {
  //     this.$store.commit("SET_MODAL_BUSCADOR", false);
  //     this.$router
  //       .push({
  //         name: "perfilActividades",
  //         params: { nombre: nombreWeb },
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }
  // private irUnidad(nombreWeb: any) {
  //   if (
  //     this.$router.currentRoute.name == "UnidadesMunicipales" &&
  //     this.$route.query.nombre == nombreWeb
  //   ) {
  //     this.$store.commit("SET_MODAL_BUSCADOR", false);
  //     this.$router.go(0);
  //   } else {
  //     this.$store.commit("SET_MODAL_BUSCADOR", false);
  //     this.$router
  //       .push({
  //         name: "UnidadesMunicipales",
  //         query: { unidad: nombreWeb },
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }
  // private irNoticia(idNoticia: any, nombreWeb: any) {
  //   if (
  //     this.$router.currentRoute.name == "Publicacion" &&
  //     this.$router.currentRoute.params.id == idNoticia
  //   ) {
  //     this.$store.commit("SET_MODAL_BUSCADOR", false);
  //     this.$router.go(0);
  //   } else if (
  //     this.$router.currentRoute.name == "Publicacion" &&
  //     this.$router.currentRoute.params.id != idNoticia
  //   ) {
  //     this.$store.commit("SET_MODAL_BUSCADOR", false);
  //     this.$router
  //       .push({
  //         name: "Publicacion",
  //         params: { id: idNoticia, nombre: nombreWeb },
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     this.$router.go(0);
  //   } else {
  //     this.$router
  //       .push({
  //         name: "Publicacion",
  //         params: { id: idNoticia, nombre: nombreWeb },
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }
  private irPagina(nombreWeb: any) {
    if (nombreWeb == "contacto") {
      this.routerGo("Contacto");
    } else if (nombreWeb == "concejo-municipal") {
      this.routerGo("Concejo");
    } else if (nombreWeb == "centros-de-atencion-municipal") {
      this.routerGo("Cam");
    } else if (nombreWeb == "oficinas-y-telefonos-municipales") {
      this.routerGo("Telefonos");
    } else {
      if (nombreWeb && nombreWeb != "") {
        if (
          this.$router.currentRoute.name == "Pagina" &&
          this.$router.currentRoute.params.nombre &&
          this.$router.currentRoute.params.nombre == nombreWeb
        ) {
          this.$router.go(0);
        } else {
          if (this.$router.currentRoute.name == "Pagina") {
            if (
              this.$router.currentRoute.params.nombre &&
              this.$router.currentRoute.params.nombre != nombreWeb
            ) {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
              this.$router.go(0);
            } else {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
            }
          } else {
            this.$router
              .push({ name: "Pagina", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
          }
        }
      }
    }
  }
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
  // private irGaleria(nombreWeb: any) {
  //   if (
  //     this.$router.currentRoute.name == "DetalleGaleria" &&
  //     this.$router.currentRoute.params.nombre == nombreWeb
  //   ) {
  //     this.$router.go(0);
  //   } else if (
  //     this.$router.currentRoute.name == "DetalleGaleria" &&
  //     this.$router.currentRoute.params.nombre != nombreWeb
  //   ) {
  //     this.$router
  //       .push({
  //         name: "DetalleGaleria",
  //         params: { nombre: nombreWeb },
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     this.$router.go(0);
  //   } else {
  //     this.$router
  //       .push({
  //         name: "DetalleGaleria",
  //         params: { nombre: nombreWeb },
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }

  private toLink(item: any) {
    if (item && item != "") {
      window.open(item, "_blank");
    } else {
      return;
    }
  }

  private routerSearch(route: string, id: any, name: string) {
    if (route && route != "" && id && id != "" && name && name != "") {
      if (
        this.$router.currentRoute.name == route &&
        this.$router.currentRoute.params.id == id
      ) {
        this.$router.go(0);
      } else if (
        this.$router.currentRoute.name == route &&
        this.$router.currentRoute.params.id != id
      ) {
        this.$router.push({ name: route, params: { id: id, nombre: name } });
        this.$router.go(0);
      } else {
        this.$router.push({ name: route, params: { id: id, nombre: name } });
        // this.$router.go(0);
      }
    }

    if (name && name != "" && route && route != "" && id == "") {
      if (
        this.$router.currentRoute.name == route &&
        this.$router.currentRoute.params.nombre == name
      ) {
        this.$router.go(0);
      } else if (
        this.$router.currentRoute.name == route &&
        this.$router.currentRoute.params.nombre != name
      ) {
        this.$router.push({ name: route, params: { nombre: name } });
        this.$router.go(0);
      } else {
        this.$router.push({ name: route, params: { nombre: name } });

        // this.$router.go(0);
      }
    }
  }

  private compare(a, b) {
    if (a.nombre < b.nombre) {
      return -1;
    }
    if (a.nombre > b.nombre) {
      return 1;
    }
    return 0;
  }
}
